/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/ngx-toastr/toastr';
// @import '~ngx-toastr/toastr-bs4-alert';
@import 'app';
@import 'pages';
@import 'sidebar';
@import '../scss/icons/font-awesome/css/font-awesome.min.css';
@import '../scss/icons/simple-line-icons/css/simple-line-icons.css';
@import '../scss/icons/themify-icons/themify-icons.css';
@import "../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import 'widgets';
@import 'grid';
@import 'responsive';
@import 'spinners.scss';
@import 'animate.css';
@import 'theme-settings.scss';
// color css
@import 'colors/default';
@import 'colors/default-dark';
// dark theme
@import 'dark';
@import 'bootstrap';
// Utils
@import 'utils';
