/*******************
Text Colors
*******************/

.text-white {
    color: $white !important;
}

.text-danger {
    color: $danger !important;
}

.text-muted {
    color: $muted !important;
}

.text-warning {
    color: $warning !important;
}

.text-success {
    color: $success !important;
}

.text-info {
    color: $info !important;
}

.text-inverse {
    color: $inverse !important;
}

html body {
    .text-primary {
        color: $primary !important;
    }
    .text-dark {
        color: $bodytext;
    }
    .text-themecolor {
        color: $themecolor;
    }
}


/*******************
Background Colors
*******************/

.bg-primary {
    background-color: $primary !important;
}

.bg-success {
    background-color: $success !important;
}

.bg-info {
    background-color: $info !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-danger {
    background-color: $danger !important;
}

html body {
    .bg-theme {
        background-color: $themecolor;
    }
    .bg-inverse {
        background-color: $inverse;
    }
    .bg-light-part {
        background-color: $bglight;
    }
    .bg-light-primary {
        background-color: $light-primary;
    }
    .bg-light-success {
        background-color: $light-success;
    }
    .bg-light-info {
        background-color: $light-info;
    }
    .bg-light-extra {
        background-color: $extra-light;
    }
    .bg-light-warning {
        background-color: $light-warning;
    }
    .bg-light-danger {
        background-color: $light-danger;
    }
    .bg-light-inverse {
        background-color: $light-inverse;
    }
    .bg-light {
        background-color: $light;
    }
    .bg-white {
        background-color: $white;
    }
}


/*******************
Rounds
*******************/

.round {
    line-height: 48px;
    color: $white;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $info;
    img {
        border-radius: 100%;
    }
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}

.round.round-info {
    background: $info;
}

.round.round-warning {
    background: $warning;
}

.round.round-danger {
    background: $danger;
}

.round.round-success {
    background: $success;
}

.round.round-primary {
    background: $primary;
}


/*******************
Buttons
******************/

.btn {
    padding: 7px 12px;
    cursor: pointer;
}

.btn-group {
    label {
        color: $white !important;
        margin-bottom: 0px;
        &.btn-secondary {
            color: $bodytext !important;
        }
    }
}

.btn-lg {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.btn-md {
    padding: 12px 55px;
    font-size: 16px;
}

.btn-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.btn-circle.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 22px;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
}

.button-list button,
.button-list a {
    margin: 5px 12px 5px 0;
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all 0.5s;
}

.btn-rounded {
    border-radius: 60px;
    padding: 7px 18px;
    &.btn-lg {
        padding: 0.75rem 1.5rem;
    }
    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 12px;
    }
    &.btn-xs {
        padding: 0.25rem 0.5rem;
        font-size: 10px;
    }
    &.btn-md {
        padding: 12px 35px;
        font-size: 16px;
    }
}

.btn-secondary,
.btn-secondary.disabled {
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    background-color: $white;
    color: $bodytext;
    border-color: $form-brd;
    &:hover {
        box-shadow: $default-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
    }
}

.btn-primary,
.btn-primary.disabled {
    color: $white !important;
    background: $primary !important;
    // border: 1px solid $primary !important;
    border: none !important;
    box-shadow: $primary-shadow !important;
    transition: 0.2s ease-in !important;
    &:hover {
        background: $primary !important;
        box-shadow: $primary-shadow-hover !important;
        border: 0px solid $primary-dark !important;
        color: $white !important;
    }
    &.active,
    &:active,
    &:focus {
        background: $primary-dark;
        box-shadow: $primary-shadow-hover;
    }
}

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:active,
    &:focus {
        background: $themecolor-dark;
    }
}

.btn-success,
.btn-success.disabled {
    background: $success;
    border: 1px solid $success;
    box-shadow: $success-shadow;
    transition: 0.2s ease-in;
    &:hover {
        background: $success;
        box-shadow: $success-shadow-hover;
        border: 1px solid $success;
    }
    &.active,
    &:active,
    &:focus {
        background: $success-dark;
        box-shadow: $success-shadow-hover;
    }
}

.btn-info,
.btn-info.disabled {
    background: $info;
    border: 1px solid $info;
    box-shadow: $info-shadow;
    transition: 0.2s ease-in;
    &:hover {
        background: $info;
        border: 1px solid $info;
        box-shadow: $info-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        background: $info-dark;
        box-shadow: $info-shadow-hover;
    }
}

.btn-warning,
.btn-warning.disabled {
    background: $warning;
    color: $white;
    box-shadow: $warning-shadow;
    border: 1px solid $warning;
    transition: 0.2s ease-in;
    color: $white;
    &:hover {
        background: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
        border: 1px solid $warning;
    }
    &.active,
    &:active,
    &:focus {
        background: $warning-dark;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }
}

.btn-danger,
.btn-danger.disabled {
    background: $danger;
    border: 1px solid $danger;
    box-shadow: $danger-shadow;
    transition: 0.2s ease-in;
    &:hover {
        background: $danger;
        box-shadow: $danger-shadow-hover;
        border: 1px solid $danger;
    }
    &.active,
    &:active,
    &:focus {
        background: $danger-dark;
        box-shadow: $danger-shadow-hover;
    }
}

.btn-inverse,
.btn-inverse.disabled {
    background: $inverse;
    border: 1px solid $inverse;
    color: $white;
    &:hover {
        background: $inverse;
        opacity: 0.7;
        color: $white;
        border: 1px solid $inverse;
    }
    &.active,
    &:active,
    &:focus {
        background: $inverse-dark;
        color: $white;
    }
}

.btn-red,
.btn-red.disabled {
    background: $red;
    border: 1px solid $red;
    color: $white;
    &:hover {
        opacity: 0.7;
        border: 1px solid $red;
        background: $red;
    }
    &.active,
    &:active,
    &:focus {
        background: $danger-dark;
    }
}

.btn-outline-secondary {
    background-color: $white;
    box-shadow: $default-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        box-shadow: $default-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $default-shadow-hover;
    }
}

.btn-outline-primary {
    color: $primary;
    background-color: $white;
    border-color: $primary;
    box-shadow: $primary-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        color: $white;
        border-color: $primary;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $primary-shadow-hover;
        background: $primary-dark;
    }
}

.btn-outline-success {
    color: $success;
    background-color: transparent;
    border-color: $success;
    box-shadow: $success-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $success;
        border-color: $success;
        color: $white;
        box-shadow: $success-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $success-shadow-hover;
        background: $success-dark;
    }
}

.btn-outline-info {
    color: $info;
    background-color: transparent;
    border-color: $info;
    box-shadow: $info-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $info;
        border-color: $info;
        color: $white;
        box-shadow: $info-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $info-shadow-hover;
        background: $info-dark;
    }
}

.btn-outline-warning {
    color: $warning;
    background-color: transparent;
    border-color: $warning;
    box-shadow: $warning-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $warning;
        border-color: $warning;
        color: $white;
        box-shadow: $warning-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $warning-shadow-hover;
        background: $warning-dark;
    }
}

.btn-outline-danger {
    color: $danger;
    background-color: transparent;
    border-color: $danger;
    box-shadow: $danger-shadow;
    transition: 0.2s ease-in;
    &:hover,
    &:focus,
    &.focus {
        background: $danger;
        border-color: $danger;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-red {
    color: $red;
    background-color: transparent;
    border-color: $red;
    &:hover,
    &:focus,
    &.focus {
        background: $red;
        border-color: $red;
        color: $white;
        box-shadow: $danger-shadow-hover;
    }
    &.active,
    &:active,
    &:focus {
        box-shadow: $danger-shadow-hover;
        background: $danger-dark;
    }
}

.btn-outline-inverse {
    color: $inverse;
    background-color: transparent;
    border-color: $inverse;
    &:hover,
    &:focus,
    &.focus {
        background: $inverse;
        border-color: $inverse;
        color: $white;
    }
}

.dropdown-item.active, .dropdown-item:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
    background-color: $primary-dark;
    border: 1px solid $primary-dark;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
    background-color: $success-dark;
    border: 1px solid $success-dark;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
    background-color: $info-dark;
    border: 1px solid $info-dark;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
    background-color: $warning-dark;
    border: 1px solid $warning-dark;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
    background-color: $danger-dark;
    border: 1px solid $danger-dark;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
    background-color: $inverse-dark;
    border: 1px solid $inverse-dark;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open>.dropdown-toggle.btn-red {
    background-color: $red-dark;
    border: 1px solid $red-dark;
    color: $white;
}

.button-box .btn {
    margin: 0 8px 8px 0px;
}

.btn-label {
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin: -6px 12px -6px -14px;
    padding: 7px 15px;
}

.btn-facebook {
    color: $white;
    background-color: #3b5998;
}

.btn-twitter {
    color: $white;
    background-color: #55acee;
}

.btn-linkedin {
    color: $white;
    background-color: #007bb6;
}

.btn-dribbble {
    color: $white;
    background-color: #ea4c89;
}

.btn-googleplus {
    color: $white;
    background-color: #dd4b39;
}

.btn-instagram {
    color: $white;
    background-color: #3f729b;
}

.btn-pinterest {
    color: $white;
    background-color: #cb2027;
}

.btn-dropbox {
    color: $white;
    background-color: #007ee5;
}

.btn-flickr {
    color: $white;
    background-color: #ff0084;
}

.btn-tumblr {
    color: $white;
    background-color: #32506d;
}

.btn-skype {
    color: $white;
    background-color: #00aff0;
}

.btn-youtube {
    color: $white;
    background-color: #bb0000;
}

.btn-github {
    color: $white;
    background-color: #171515;
}

