// Variables
@import "colors/default";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
$bodyfont:'Poppins',
sans-serif;
$headingfont:'Poppins',
sans-serif;

/*Light colors*/

$light-danger: lighten($danger,
20%);
$light-success: lighten($success,
20%);
$light-warning: lighten($warning,
20%);
$light-primary: lighten($primary,
20%);
$light-info: lighten($info,
20%);
$light-inverse: lighten($inverse,
20%);
$danger-dark: darken($danger,
20%);
$success-dark: darken($success,
20%);
$warning-dark: darken($warning,
20%);
$primary-dark: darken($dark,
20%);
$info-dark: darken($info,
20%);
$red-dark: darken($red,
20%);
$inverse-dark: darken($inverse,
20%);
$dark-transparent:rgba(0,
0,
0,
0.05);
$info-shadow: 0 2px 2px 0 rgba(66,
165,
245,
.14),
0 3px 1px -2px rgba(66,
165,
245,
.2),
0 1px 5px 0 rgba(66,
165,
245,
.12);
$info-shadow-hover:0 14px 26px -12px rgba(23,
105,
255,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(23,
105,
255,
.2);
$warning-shadow:0 2px 2px 0 rgba(248,
194,
0,
.14),
0 3px 1px -2px rgba(248,
194,
0,
.2),
0 1px 5px 0 rgba(248,
194,
0,
.12);
$warning-shadow-hover:0 14px 26px -12px rgba(248,
194,
0,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(248,
194,
0,
.2);
$danger-shadow:0 2px 2px 0 rgba(239,
83,
80,
.14),
0 3px 1px -2px rgba(239,
83,
80,
.2),
0 1px 5px 0 rgba(239,
83,
80,
.12);
$danger-shadow-hover:0 14px 26px -12px rgba(239,
83,
80,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(239,
83,
80,
.2);
$success-shadow:0 2px 2px 0 rgba(40,
190,
189,
.14),
0 3px 1px -2px rgba(40,
190,
189,
.2),
0 1px 5px 0 rgba(40,
190,
189,
.12);
$success-shadow-hover:0 14px 26px -12px rgba(40,
190,
189,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(40,
190,
189,
.2);
$primary-shadow:0 2px 2px 0 rgba(116,
96,
238,
.14),
0 3px 1px -2px rgba(116,
96,
238,
.2),
0 1px 5px 0 rgba(116,
96,
238,
.12);
$primary-shadow-hover:0 14px 26px -12px rgba(116,
96,
238,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(116,
96,
238,
.2);
$default-shadow:0 2px 2px 0 rgba(169,
169,
169,
.14),
0 3px 1px -2px rgba(169,
169,
169,
.2),
0 1px 5px 0 rgba(169,
169,
169,
.12);
$default-shadow-hover:0 14px 26px -12px rgba(169,
169,
169,
.42),
0 4px 23px 0 rgba(0,
0,
0,
.12),
0 8px 10px -5px rgba(169,
169,
169,
.2);

/*Extra Variable*/

$rgt: right;
$lft: left;
$border: rgba(120,
130,
140,
0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #b1b8bb;

/*Preloader*/

.preloader {
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel {
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}