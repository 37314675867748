/*******************
/*User mail widgets*/
/*******************/

.topbar {
    .top-navbar {
        .mailbox {
            width: 300px;
            ul {
                padding: 0px;
                li {
                    list-style: none;
                }
            }
        }
    }
}

.mailbox {
    ul li {
        .drop-title {
            font-weight: 500;
            padding: 11px 20px 15px;
            border-bottom: 1px solid $border;
        }
        .nav-link {
            border-top: 1px solid $border;
            padding-top: 15px;
        }
    }
    .message-center {
        height: 200px;
        overflow: auto;
        position: relative;
        a {
            border-bottom: 1px solid $border;
            display: block;
            text-decoration: none;
            padding: 9px 15px;
            &:hover {
                background: $light;
            }
            div {
                white-space: normal;
            }
            .user-img {
                width: 40px;
                position: relative;
                display: inline-block;
                margin: 0 10px 15px 0;
                img {
                    width: 100%;
                }
                .profile-status {
                    border: 2px solid $white;
                    border-radius: 50%;
                    display: inline-block;
                    height: 10px;
                    left: 30px;
                    position: absolute;
                    top: 1px;
                    width: 10px;
                }
                .online {
                    background: $success;
                }
                .busy {
                    background: $danger;
                }
                .away {
                    background: $warning;
                }
                .offline {
                    background: $warning;
                }
            }
            .mail-contnet {
                display: inline-block;
                width: 75%;
                vertical-align: middle;
                h5 {
                    margin: 5px 0px 0;
                }
                .mail-desc,
                .time {
                    font-size: 12px;
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $bodytext;
                    white-space: nowrap;
                }
            }
        }
    }
}



/*******************
/*States row*/
/*******************/

.stats-row {
    margin-bottom: 20px;
    .stat-item {
        display: inline-block;
        padding-right: 15px;
        &+.stat-item {
            padding-left: 15px;
            border-left: 1px solid $border;
        }
    }
}




/*******************/
/*Comment widgets*/
/*******************/

.comment-widgets {
    position: relative;
    margin-bottom: 10px;
    .comment-row {
        border-bottom:1px solid $border;
        padding: 15px;
        &:last-child{
            border-bottom:0px;
        }
        &:hover,
        &.active {
            background:$bglight;
        }
    }
}

.comment-text {
    padding: 15px 15px 15px 20px;
    width:80%;
    &:hover .comment-footer,
    &.active .comment-footer {
        .action-icons {
            visibility: visible;
        }
    }
    p {
        max-height: 50px;
        width:100%;
        overflow: hidden;
        
    }
}

.comment-footer {
    .action-icons {
        visibility: hidden;
        a {
            padding-left: 7px;
            vertical-align: middle;
            color: $muted;
            &:hover,
            &.active {
                color: $info;
            }
        }
    }
}


/*******************/
/*To do widgets*/
/*******************/

.todo-list {
    li {
        border: 0px;
        margin-bottom: 0px;
        padding: 20px 15px 15px 0px;
        .checkbox {
            width: 100%;
            label {
                font-weight: 400;
                color:$headingtext;
            }
        }
        &:last-child {
            border-bottom: 0px;
        }
        .assignedto {
            padding: 0px 0 0 27px;
            margin: 0px;
            li {
                list-style: none;
                padding: 0px;
                display: inline-block;
                border: 0px;
                margin-right: 2px;
                img {
                    width: 30px;
                    border-radius: 100%;
                }
            }
        }
        .item-date {
            padding-left: 25px;
            font-size: 12px;
            display: inline-block;
        }
    }
}

.list-task .task-done span {
    text-decoration: line-through;
}




/*******************/
/*Chat widget*/
/*******************/

.message-box {
    ul li {
        .drop-title {
            font-weight: 500;
            padding: 11px 20px 15px;
            border-bottom: 1px solid $border;
        }
        .nav-link {
            border-top: 1px solid $border;
            padding-top: 15px;
        }
    }
    .message-widget {
        position: relative;
        a {
            border-bottom: 1px solid $border;
            display: block;
            text-decoration: none;
            padding: 9px 15px;
            &:hover {
                background: $light;
            }
            &:last-child {
                border-bottom: 0px;
            }
            div {
                white-space: normal;
            }
            .user-img {
                width: 45px;
                position: relative;
                display: inline-block;
                margin: 0 10px 15px 0;
                img {
                    width: 100%;
                }
                .profile-status {
                    border: 2px solid $white;
                    border-radius: 50%;
                    display: inline-block;
                    height: 10px;
                    left: 33px;
                    position: absolute;
                    top: -1px;
                    width: 10px;
                }
                .online {
                    background: $success;
                }
                .busy {
                    background: $danger;
                }
                .away {
                    background: $warning;
                }
                .offline {
                    background: $warning;
                }
            }
            .mail-contnet {
                display: inline-block;
                width: 73%;
                vertical-align: middle;
                h5 {
                    margin: 5px 0px 0;
                }
                .mail-desc,
                .time {
                    font-size: 12px;
                    display: block;
                    margin: 1px 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $bodytext;
                    white-space: nowrap;
                }
            }
        }
    }
}


/*******************/
/*Steam line widget*/
/*******************/
.steamline {
    position: relative;
    border-left: 1px solid $border;
    margin-left: 20px;
    .sl-left {
        float: $lft;
        margin-left: -20px;
        z-index: 1;
        width: 40px;
        line-height: 40px;
        text-align: center;
        height: 40px;
        border-radius: 100%;
        color: $white;
        background: $dark;
        margin-right: 15px;
        img {
            max-width: 40px;
        }
    }
}

.steamline .sl-right {
    padding-left: 50px;
    .desc,
    .inline-photos {
        margin-bottom: 30px;
    }
    
}

.steamline .sl-item {
    border-bottom: 1px solid $border;
    margin: 20px 0;
}

.sl-date {
    font-size: 10px;
    color: $muted;
}

.time-item {
    border-color: $border;
    padding-bottom: 1px;
    position: relative;
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        background-color: $white;
        border-color: $border;
        border-radius: 10px;
        border-style: solid;
        border-width: 2px;
        bottom: 0;
        content: '';
        height: 14px;
        left: 0;
        margin-left: -8px;
        position: absolute;
        top: 5px;
        width: 14px;
    }
}

.time-item-item {
    &:after {
        content: " ";
        display: table;
    }
}

.item-info {
    margin-bottom: 15px;
    margin-left: 15px;
    p {
        margin-bottom: 10px !important;
    }
}

/*******************/
/*Feed widget*/
/*******************/

.feeds {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        padding: 10px;
        display: block;
        &:hover {
            background: $extra-light;
        }
        > div {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            border-radius: 100%;
            i {
                line-height: 40px;
            }
        }
        span {
            float: right;
            width: auto;
            font-size: 12px;
        }
    }
}


/*******************/
/*Vertical carousel*/
/*******************/

.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}


/*******************/
/*social-widgets*/
/*******************/

.social-widget {
    .soc-header {
        padding: 15px;
        text-align: center;
        font-size: 36px;
        color: #fff;
        &.box-facebook {
            background: #3b5998;
        }
        &.box-twitter {
            background: #00aced;
        }
        &.box-google {
            background: #f86c6b;
        }
        &.box-linkedin {
            background: #4875b4;
        }
    }
    .soc-content {
        display: flex;
        text-align: center;
        div {
            padding: 10px;
            h3 {
                margin-bottom: 0px;
            }
        }
    }
}


/*******************/
/*social-profile-first*/
/*******************/

.social-profile-first {
    text-align: center;
    padding-top: 22%;
    margin-bottom: 96px;
    &.bg-over {
        background: rgba(56, 83, 161, 0.7);
    }
    .middle {
        vertical-align: middle;
    }
}


/*******************/
/*country-state*/
/*******************/

.country-state {
    list-style: none;
    margin: 0;
    padding: 0 0 0 10px;
    li {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 0px;
        font-weight: 400;
    }
}


/*******************/
/*profile timeline widget*/
/*******************/

.profiletimeline {
    position: relative;
    padding-left: 40px;
    margin-right: 10px;
    border-left:1px solid $border;
    margin-left:30px;
    .sl-left {
        float: left;
        margin-left: -60px;
        z-index: 1;
        margin-right: 15px;
        img {
            max-width: 40px;
        }
    }
}
.profiletimeline .sl-item {
    margin-top: 8px;
    margin-bottom: 30px;
}

.profiletimeline .sl-date {
    font-size: 12px;
    color: $muted;
}

.profiletimeline .time-item {
    border-color: $border;
    padding-bottom: 1px;
    position: relative;
    &:before {
        content: " ";
        display: table;
    }
    &:after {
        background-color: $white;
        border-color: $border;
        border-radius: 10px;
        border-style: solid;
        border-width: 2px;
        bottom: 0;
        content: '';
        height: 14px;
        left: 0;
        margin-left: -8px;
        position: absolute;
        top: 5px;
        width: 14px;
    }
}

.profiletimeline .time-item-item {
    &:after {
        content: " ";
        display: table;
    }
}

.profiletimeline .item-info {
    margin-bottom: 15px;
    margin-left: 15px;
    p {
        margin-bottom: 10px !important;
    }
}

/*Blog widgets*/

.blog-widget{
    margin-top: 30px;
    .blog-image img{
        border-radius: $radius;
        margin-top: -45px;
        margin-bottom: 20px;
        box-shadow: 0 0 15px rgba(0, 0, 0,0.2);
    }
}



/*little-profile*/
.little-profile{
    .pro-img{
        margin-top: -80px;
        margin-bottom: 20px;
        img{
            width:128px;
            height: 128px;
            box-shadow: 0 0 15px rgba(0, 0, 0,0.2);
            border-radius: 100%;
        }
    }
}
.contact-box {
    position: relative;
    .add-ct-btn{
        position: absolute;
        right: 4px;
        top:-46px;
    }
    .contact-widget{
    > a{
        padding: 15px 10px;
        .user-img{
            margin-bottom: 0px!important;
        }
    }
}
}    