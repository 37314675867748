$themecolor: #ED1C24;
$themecolor-alt: #135BAA;
$themecolor-dark: #135BAA;
$topbar: #ED1C24;
$topbar-alt: #ffffff;
$sidebar:#ffffff;
$sidebar-alt: #181c22;
$sidebar-dark: #181c22;
$sidebar-icons: #798699;
$sidebar-text: #798699;
$sidebar-white: #e5edef;
$theme-dark:#272c33;
$theme-dark-alt: #3d4554;
$theme-dark-odd: #1f2227;

$bodycolor: #eef5f9;
$bodytext: #7d8b92;
$headingtext: #455a64;
$light-text: #a6b7bf;

/*Bootstrap Colors*/
$danger: #f32a27;
$success: #01b351;
$warning: #ffbd2d;
$primary: $themecolor;
$secondary: $themecolor-dark;
$tertiairy: $themecolor-alt;
$info: #1976d2;
$inverse: #2f3d4a;
$muted: #99abb4;
$medium: $muted;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$extralight: #ebf3f5;
$bglight:rgba(0, 0, 0, 0.02);

/*Named Colors*/

$white: #ffffff;
$red: #fb3a3a;
$yellow: #f1db0d;
$blue: #0276c9;
$black: #000000;