$themecolor: #ED1C24;
$themecolor-alt: #135BAA;
$themecolor-dark: #135BAA;
$topbar: #ED1C24;
$topbar-alt: #075f72;
$sidebar:#1d090d;
$sidebar-text: #798699;
$sidebar-icons: #798699;
$sidebar-dark: #1d090d;
$sidebar-alt: #443034;
$theme-dark:#1d090d;
$theme-dark-alt: #443034;
$theme-dark-odd: #1d090d;

$bodycolor: #eef5f9;
$bodytext: #7d8b92;
$headingtext: #455a64;
$light-text: #a6b7bf;

/*Bootstrap Colors*/

$danger: #ef5350;
$success: #0b7b59;
$warning: #ffb22b;
$primary: $themecolor;
$secondary: $themecolor-dark;
$tertiairy: $themecolor-alt;
$info: #1976d2;
$inverse: #2f3d4a;
$muted: #99abb4;
$medium: $muted;
$dark: #1d090d;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$extralight: #ebf3f5;
$bglight:rgba(0, 0, 0, 0.02);

/*Named Colors*/

$white: #ffffff;
$red: #fb3a3a;
$yellow: #f1db0d;
$blue: #0276c9;
$black: #000000;